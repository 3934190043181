import React, { useEffect, useState } from 'react';

const GenusInfo = ({ GenusName }) => {
  const [infoData, setInfoData] = useState([]); // State to hold information data

  useEffect(() => {
    // Fetch genus information from a local JSON file
    fetch('/genus_info.json')
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => setInfoData(data)) // Set the fetched data to state
      .catch((error) => console.error('Error fetching info data:', error)); // Log errors if fetching fails
  }, []); // Empty dependency array means this runs once on mount

  // Find the information for the given genus name
  const genusInfo = infoData.find((item) => item.genus === GenusName);

  return (
    <div>
      {genusInfo ? ( // Check if information for the genus is found
        <p>{genusInfo.Intro}</p> // Display the introductory information
      ) : (
        <p>No information available for {GenusName}</p> // Fallback message if not found
      )}
    </div>
  );
};

export default GenusInfo;
