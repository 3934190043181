// Icons.js
import L from "leaflet";
import 'leaflet/dist/leaflet.css';

// Tree icon
export const redDotIcon = L.divIcon({
  html: `<div class="tree-button"></div>`,
  iconSize: [12, 12],
  className: "Tree-icon"
});

// Location icon
export const blueDotIcon = L.divIcon({
  html: `<div class="pulsing-button"></div>`,
  iconSize: [12, 12],
  className: "Location-icon"
});


// Location icon
export const blueDotIconStatic = L.divIcon({
  html: `<div class="static-button"></div>`,
  iconSize: [12, 12],
  className: "Location-icon"
});


