// MarkersWithCluster.js
import { useEffect } from "react";
import { useMapEvents } from "react-leaflet";
import L from "leaflet";
import 'leaflet.markercluster/dist/leaflet.markercluster'; // Import Leaflet marker clustering functionality
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'; // Import default styles for marker clustering
import './Markers.css'; // Custom CSS for markers
import { ColorPalette } from "./colorpalette.js";

const MarkersWithCluster = ({ markersData, onMarkerClick, availableGenera, selectedGenera }) => {
  const map = useMapEvents({});
  const markerClusterGroup = L.markerClusterGroup({
    disableClusteringAtZoom: 18,
    spiderfyOnMaxZoom: false,
    showCoverageOnHover: false,
    iconCreateFunction: (cluster) => {
      const count = cluster.getChildCount();
      let className;
      if (count < 1) {
        className = 'marker-cluster-small';
      } else if (count >= 1 && count < 30) {
        className = 'marker-cluster-medium';
      } else {
        className = 'marker-cluster-large';
      }
      return L.divIcon({
        html: `<div class="${className}"><span>${count}</span></div>`,
        className: 'marker-cluster',
        iconSize: L.point(50, 50, true),
      });
    }
  });

  useEffect(() => {
    markerClusterGroup.clearLayers(); // Clear previous markers

    const filteredMarkers = markersData.filter(marker =>
      selectedGenera.length === 0 || selectedGenera.includes(marker.genus)
    );

    filteredMarkers.forEach(marker => {
      const markerColor = ColorPalette[marker.genus]?.color || "#FF0000";
      const icon = L.divIcon({
        className: 'custom-marker-icon',
        html: `<div class="custom-marker-inner" style="background-color:${markerColor};"></div>`
      });

      const newMarker = L.marker([marker.lat, marker.lng], { icon });
      newMarker.on('click', () => onMarkerClick(marker));
      markerClusterGroup.addLayer(newMarker);
    });

    map.addLayer(markerClusterGroup);

    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [markersData, map, markerClusterGroup, onMarkerClick, selectedGenera]);

  return null; // No need to render anything here
};

export default MarkersWithCluster;
