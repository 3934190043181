import React, { useState, useEffect} from 'react';
import { ResponsivePie } from '@nivo/pie';
import { neighbourhoodData } from './neighbourhoods.js';

const Stats = ({ visible, onClose, availableNeighbourhood, onPieChartClick }) => {

  const [selectedNeighborhood, setSelectedNeighborhood] = useState('All Neighboorhoods');
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Find data for the selected neighborhood
    const selectedData = neighbourhoodData[selectedNeighborhood] || [];
    setChartData(selectedData);
  }, [selectedNeighborhood]);


  const handleNeighborhoodClick = (hood) => {
    setSelectedNeighborhood(hood);
  };

  // State to manage window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderContent = () => {
    if (windowWidth < 700) {
    return(
        <div className="content-wrapper" style={{ flexDirection: 'column' }}>
          {/* Column 1: Tree Information */}
          <div className="info-column" style={{ width: '90%' }}>
            <h2>Neighbourhood Statistics:</h2>
            <br />
            <p>
              The dataset on display here presents all trees on boulevards in Vancouver. Note that park and private trees are not incorporated. 
              This does not prevent you from exploring further. Have you checked out the statistics of your neighborhood?
            </p>

             <div style={{
              height: '70vw',          // Full viewport height
              display: 'flex',          // Enable flexbox
              justifyContent: 'center', // Center horizontally
            }}>
            
              <div style={{width: '100%' }}> {/* Full width of the container */}
            
            <ResponsivePie
                data={chartData}
                margin={{ top: 0, right: 10, bottom: 10, left: 10 }}
                innerRadius={0.5}
                sortByValue={true}
                padAngle={0.1}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                arcLinkLabelsSkipAngle={300}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}       
            />
              </div>
            </div>

            <div className="checkbox-container">
              {availableNeighbourhood.map((hood) => (
                <label 
                  key={hood} 
                  className="checkbox-label"
                  onClick={() => handleNeighborhoodClick(hood)}
                >
                  <div
                    className="circle-checkbox"
                    style={{ backgroundColor: selectedNeighborhood === hood ? 'hsl(20, 79%, 83%)' : 'white' }}
                  />
                  {hood}
                </label>
              ))}
            </div>

          {/* Column 2: Chart Display */}
          </div>
        </div> 
        )}else{
      return(
               <div className="content-wrapper" style={{ flexDirection: 'row' }}>
          {/* Column 1: Tree Information */}
          <div className="info-column" style={{ width: '20%' }}>
            <h2>Neighbourhood Statistics:</h2>
            <br />
            <p>
              The dataset on display here presents all trees on boulevards in Vancouver. Note that park and private trees are not incorporated. 
              This does not prevent you from exploring further. Have you checked out the statistics of your neighborhood?
            </p>
            <div className="checkbox-container">
              {availableNeighbourhood.map((hood) => (
                <label 
                  key={hood} 
                  className="checkbox-label"
                  onClick={() => handleNeighborhoodClick(hood)}
                >
                  <div
                    className="circle-checkbox"
                    style={{ backgroundColor: selectedNeighborhood === hood ? 'rgba(0, 0, 0, 0.5)' : 'white' }}
                  />
                  {hood}
                </label>
              ))}
            </div>
          </div>

          {/* Column 2: Chart Display */}
          <div className="info-column" style={{ width: '50%' }}>
            <div style={{
              height: '65vh',          // Full viewport height
              display: 'flex',          // Enable flexbox
              justifyContent: 'center', // Center horizontally
            }}>
            
              <div style={{width: '100%' }}> {/* Full width of the container */}
            
            <ResponsivePie
                data={chartData}
                margin={{ top: 0, right: 95, bottom: 0, left: 95 }}
                innerRadius={0.5}
                sortByValue={true}
                padAngle={0.1}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                arcLinkLabelsSkipAngle={5}
                arcLinkLabelsTextColor="rgba(0,0,0,1)"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabelsRadiusOffset={0.2}
                arcLabelsSkipAngle={10}
                arcLinkLabelsDiagonalLength={20}
                arcLinkLabelsStraightLength={5}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                    onClick={(e) => {
              const { id, value, color } = e;
               console.log(`Clicked on: ${id}, Value: ${value}, Color: ${color}`);
                 // You can perform any action based on the clicked element
                }}
            />
              </div>

          </div>
        </div> 
        </div>
    )}
  }

  return (
    <div 
      className="overlay-background" 
      style={{ display: visible ? 'block' : 'none' }}
      onClick={onClose}
    >
      <div className="overlay-box" onClick={(e) => e.stopPropagation()}>
        <button 
          onClick={onClose} 
          style={{ 
            position: 'absolute', 
            top: '10px', 
            right: '10px', 
            color: 'black',
            backgroundColor: 'transparent', 
            border: 'none', 
            fontSize: '20px', 
            cursor: 'pointer'
          }}
        >
          &times;
        </button>
        {renderContent()}
      </div>

    </div>
  );
};

export default Stats;
