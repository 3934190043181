import React from 'react';
import ColorPalette from './colorpalette.js'; // Adjust the path accordingly

const Filter = ({ visible, onClose, availableGenera, selectedGenera, onFilterChange }) => {
  
  // Helper function to compare arrays
  const areArraysEqual = (arr1, arr2) => {
    return arr1.length === arr2.length && arr1.every(item => arr2.includes(item));
  };

  // Define filter groups
  const coniferousGenera = availableGenera.filter(genus => ColorPalette[genus]?.category === "Coniferous");
  const deciduousGenera = availableGenera.filter(genus => ColorPalette[genus]?.category === "Deciduous");
  const rareGenera = [
    'Michelia', 'Cunninghamia', 'Pterostyrax', 'Kalopanax', 'Carya',
    'Ptelea', 'Lagerstroemia', 'Eucalyptus', 'Chitalpa', 'Clerodendrum',
    'Oxydendrum', 'Cupressocyparis', 'Hippophae', 'Stump', 'Sciadopitys',
    'Ailanthus', 'Aralia', 'Laurus', 'Albizia', 'Phellodendron',
    'Viburnum', 'Sequoia', 'Arbutus'
  ];

  const availableRareGenera = rareGenera.filter(genus => availableGenera.includes(genus));

  // Filter handler functions
  const handleCheckAll = () => {
    availableGenera.forEach(genus => {
      if (!selectedGenera.includes(genus)) {
        onFilterChange(genus);
      }
    });
  };

  const handleUncheckAll = () => {
    selectedGenera.forEach(genus => {
      onFilterChange(genus);
    });
  };

  const handleConiferous = () => {
    handleUncheckAll();
    coniferousGenera.forEach(genus => {
      onFilterChange(genus);
    });
  };

  const handleDeciduous = () => {
    handleUncheckAll();
    deciduousGenera.forEach(genus => {
      onFilterChange(genus);
    });
  };

  const handleRare = () => {
    handleUncheckAll();
    availableRareGenera.forEach(genus => {
      onFilterChange(genus);
    });
  };

  // Selection status checks
  const isAllSelected = areArraysEqual(selectedGenera, availableGenera);
  const isNoneSelected = selectedGenera.length === 0;
  const isConiferousSelected = areArraysEqual(selectedGenera, coniferousGenera);
  const isDeciduousSelected = areArraysEqual(selectedGenera, deciduousGenera);
  const isRareSelected = areArraysEqual(selectedGenera, availableRareGenera);

  return (
    <div 
      className="overlay-background" 
      style={{ display: visible ? 'block' : 'none' }}
      onClick={onClose}
    >
      <div className="overlay-box" onClick={e => e.stopPropagation()}>
        <button 
          onClick={onClose} 
          style={{ 
            position: 'absolute', 
            top: '10px', 
            right: '10px', 
            color: 'black',
            backgroundColor: 'transparent', 
            border: 'none', 
            fontSize: '20px', 
            cursor: 'pointer'
          }}
        >
          &times;
        </button>

        <h2>General filters:</h2>
        <div className="checkbox-container-over">
          
          <label className="checkbox-label" onClick={(e) => { e.stopPropagation(); handleCheckAll(); }}>
            <div className={`circle-checkbox ${isAllSelected ? 'checked' : ''}`}
              style={{ backgroundColor: isAllSelected ? '#00FF00' : 'white' }} />
            Check All
          </label>

          <label className="checkbox-label" onClick={(e) => { e.stopPropagation(); handleUncheckAll(); }}>
            <div className={`circle-checkbox ${isNoneSelected ? 'checked' : ''}`}
              style={{ backgroundColor: isNoneSelected ? '#FF0000' : 'white' }} />
            Uncheck All
          </label>

          <label className="checkbox-label" onClick={(e) => { e.stopPropagation(); handleConiferous(); }}>
            <div className={`circle-checkbox ${isConiferousSelected ? 'checked' : ''}`}
              style={{ backgroundColor: isConiferousSelected ? '#00FF00' : 'white' }} />
            Only Coniferous Trees
          </label>

          <label className="checkbox-label" onClick={(e) => { e.stopPropagation(); handleDeciduous(); }}>
            <div className={`circle-checkbox ${isDeciduousSelected ? 'checked' : ''}`}
              style={{ backgroundColor: isDeciduousSelected ? '#00FF00' : 'white' }} />
            Only Deciduous Trees
          </label>
          
          <label className="checkbox-label" onClick={(e) => { e.stopPropagation(); handleRare(); }}>
            <div className={`circle-checkbox ${isRareSelected ? 'checked' : ''}`}
              style={{ backgroundColor: isRareSelected ? '#00FF00' : 'white' }} />
            5% Rarest Species
          </label>
        </div>
        <br></br>
        <h2>Filter by species:</h2>
        <div className="checkbox-container">
          {availableGenera.map(genus => {
            const commonName = ColorPalette[genus]?.commonName || genus; // Fallback to the genus if no common name
            const markerColor = ColorPalette[genus]?.color || "#FF0000"; // Get the color for the genus
            
            return (
              <label key={genus} className="checkbox-label" onClick={(e) => { e.stopPropagation(); onFilterChange(genus); }}>
                <div
                  className={`circle-checkbox ${selectedGenera.includes(genus) ? 'checked' : ''}`}
                  style={{ backgroundColor: selectedGenera.includes(genus) ? markerColor : 'white' }}
                />
                {commonName}
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Filter;
