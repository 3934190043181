// App.js
// Importing necessary components and hooks for the main application
import './App.css'; // Main CSS file for styling the app
import Map from './components/map.js'; // Map component for displaying map with markers
import Header from './components/header.js'; // Header component (conditionally rendered)
import Overlay from './components/overlay.js'; // Overlay component for showing marker information
import { useState } from 'react'; // State management hook
import Filter from './components/filter.js'
import ColorPalette from './components/colorpalette.js'
import Stats from './components/stats.js'
import neighbourhoodData from './components/neighbourhoods.js'

function App() {
  
  // State to toggle Header and Footer visibility
  const [showHeaderFooter, setShowHeaderFooter] = useState(true); 
  // State to control Overlay visibility
  const [overlayVisible, setOverlayVisible] = useState(false); 
  // State to store the data for the Overlay (e.g., marker information)
  const [overlayContent, setOverlayContent] = useState(null); 
  // State to control Filter visibility
  const [filterVisible, setFilterVisible] = useState(false); 
  // State for selected genera
  const [selectedGenera, setSelectedGenera] = useState(Object.keys(ColorPalette)); 
  // State for selected genera
  const [statsVisible, setStatsVisible] = useState(false); 


const handleFilterChange = (genus) => {
  console.log(`Toggle genus: ${genus}`);
  setSelectedGenera(prev => {
    if (prev.includes(genus)) {
      console.log(`Removing ${genus}`);
      return prev.filter(g => g !== genus);
    }
    console.log(`Adding ${genus}`);
    console.log(`Selected genera ${selectedGenera}`);
    return [...prev, genus];
  });
};

  // Handle marker click: show Overlay and hide Header/Footer
  const handleMarkerClick = (marker) => {
    setOverlayContent(marker); // Set clicked marker's data
    setOverlayVisible(true); // Show Overlay
    setShowHeaderFooter(false); // Hide Header and Footer
  };
  
  // Handle Overlay close: hide Overlay and show Header/Footer again
  const handleOverlayClose = () => {
    setOverlayVisible(false); // Hide Overlay
    setShowHeaderFooter(true); // Show Header and Footer
  };

    // Function to open the filter modal
  const openFilter = () => {
    setFilterVisible(true);
    setShowHeaderFooter(false); // Optionally hide header/footer when filter is open
  };

  // Function to close the filter modal
  const closeFilter = () => {
    setFilterVisible(false);
    setShowHeaderFooter(true); // Show header/footer again when filter is closed
  };

  // Function to close the stats modal
  const openStats = () => {
    setStatsVisible(true);
    setShowHeaderFooter(false); // Show header/footer again when filter is closed
  };


  // Function to close the stats modal
  const closeStats = () => {
    setStatsVisible(false);
    setShowHeaderFooter(true); // Show header/footer again when filter is closed
  };

  return (
    <div className="App">
      {/* Map component, passing down relevant handlers */}
      <Map 
        onMarkerClick={handleMarkerClick} 
        onOverlayClose={handleOverlayClose} 
        overlayVisible={overlayVisible} 
        filterVisible={filterVisible}
        statsVisible={statsVisible}
        openFilter={openFilter} 
        openStats={openStats}
        selectedGenera={selectedGenera} // Pass selected genera to Map        
       /> 
      
      {/* Conditionally render Header and Footer if state allows */}
      {showHeaderFooter && <Header />} 
      
      {/* Conditionally render Overlay if it's visible */}
      {overlayVisible && (
        <Overlay 
          visible={overlayVisible}  // Pass visibility state to Overlay
          content={overlayContent}  // Pass marker data to display
          onClose={handleOverlayClose} // Function to close the Overlay
        />
      )}

      {/* Conditionally render Filter if it's visible */}
     {filterVisible && (
        <Filter 
          visible={filterVisible} 
          onClose={closeFilter} 
          availableGenera={Object.keys(ColorPalette)} // You might need to load available genera from somewhere
          selectedGenera={selectedGenera} // Pass selected genera to Filter
          onFilterChange={handleFilterChange} // Pass filter change handler
        />
      )}

      {/* Conditionally render Stats if it's visible */}
     {statsVisible && (
        <Stats
        visible={statsVisible}
        onClose={closeStats}
        availableNeighbourhood={Object.keys(neighbourhoodData)}
        />
      )}


    </div>
  );
}

export default App;
