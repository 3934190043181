

export const ColorPalette = {
  "Rhus": { color: "#f6c9b2", commonName: "Sumac", category: "Deciduous" },
  "Styrax": { color: "#b6e0f4", commonName: "Styrax", category: "Deciduous" },
  "Ailanthus": { color: "#f9e3b3", commonName: "Tree of Heaven", category: "Deciduous" },
  "Sequoiadendron": { color: "#c4f7e5", commonName: "Giant Sequoia", category: "Coniferous" },
  "Laburnum": { color: "#ffe9b3", commonName: "Golden Chain", category: "Deciduous" },
  "Pterocarya": { color: "#b3e0c4", commonName: "Wingnut", category: "Deciduous" },
  "Syringa": { color: "#e1c6f0", commonName: "Lilac", category: "Deciduous" },
  "Ulmus": { color: "#a3d1f0", commonName: "Elm", category: "Deciduous" },
  "Crataegus": { color: "#f2a5a5", commonName: "Hawthorn", category: "Deciduous" },
  "Cercidiphyllum": { color: "#c6a0e5", commonName: "Katsura Tree", category: "Deciduous" },
  "Abies": { color: "#a2c8a5", commonName: "Fir", category: "Coniferous" },
  "Eucommia": { color: "#f7d3a3", commonName: "Eucommia", category: "Deciduous" },
  "Liquidambar": { color: "#e3f4a5", commonName: "Sweetgum", category: "Deciduous" },
  "Salix": { color: "#b5e3c8", commonName: "Willow", category: "Deciduous" },
  "Picea": { color: "#c7f1f5", commonName: "Spruce", category: "Coniferous" },
  "Platanus": { color: "#d5c1b8", commonName: "Plane Tree", category: "Deciduous" },
  "Catalpa": { color: "#f2f2a5", commonName: "Catalpa", category: "Deciduous" },
  "Larix": { color: "#a3e4c1", commonName: "Larch", category: "Coniferous" },
  "Ilex": { color: "#b3d6d0", commonName: "Holly", category: "Deciduous" },
  "Maackia": { color: "#f5d6b5", commonName: "Maackia", category: "Deciduous" },
  "Oxydendrum": { color: "#c9e4f5", commonName: "Sourwood", category: "Deciduous" },
  "Carpinus": { color: "#b2e5f5", commonName: "Hornbeam", category: "Deciduous" },
  "Pseudotsuga": { color: "#b8e1c5", commonName: "Douglas Fir", category: "Coniferous" },
  "Cryptomeria": { color: "#d9c2f4", commonName: "Japanese Cedar", category: "Coniferous" },
  "Castanea": { color: "#f1d2a0", commonName: "Chestnut", category: "Deciduous" },
  "Gymnocladus": { color: "#c4d7a5", commonName: "Kentucky Coffee Tree", category: "Deciduous" },
  "Phellodendron": { color: "#f9c8b3", commonName: "Phellodendron", category: "Deciduous" },
  "Mespilus": { color: "#e1d5a6", commonName: "Medlar", category: "Deciduous" },
  "Clerodendrum": { color: "#f4b5e3", commonName: "Clerodendrum", category: "Deciduous" },
  "Trachycarpus": { color: "#f5e0a5", commonName: "Trachycarpus", category: "Deciduous" },
  "Stump": { color: "#d8b8b0", commonName: "Stump", category: "Deciduous" },
  "Carya": { color: "#b9a66d", commonName: "Hickory", category: "Deciduous" },
  "Koelreuteria": { color: "#f5f1a5", commonName: "Goldenrain Tree", category: "Deciduous" },
  "Viburnum": { color: "#f1e4b4", commonName: "Viburnum", category: "Deciduous" },
  "Kalopanax": { color: "#c9e2b6", commonName: "Kalopanax", category: "Deciduous" },
  "Corylus": { color: "#e8c6c6", commonName: "Hazelnut", category: "Deciduous" },
  "Metasequoia": { color: "#d3f2c4", commonName: "Dawn Redwood", category: "Coniferous" },
  "Davidia": { color: "#f5d6c6", commonName: "Dove Tree", category: "Deciduous" },
  "Ostrya": { color: "#c4d4b8", commonName: "Ironwood", category: "Deciduous" },
  "Populus": { color: "#b3d6f4", commonName: "Poplar", category: "Deciduous" },
  "Pterostyrax": { color: "#f5c8a3", commonName: "Pterostyrax", category: "Deciduous" },
  "Taxodium": { color: "#d5f2a5", commonName: "Bald Cypress", category: "Coniferous" },
  "Parrotia": { color: "#f9c9c9", commonName: "Parrotia", category: "Deciduous" },
  "Chitalpa": { color: "#e1a6f0", commonName: "Chitalpa", category: "Deciduous" },
  "Fraxinus": { color: "#e0d6c6", commonName: "Ash", category: "Deciduous" },
  "Nyssa": { color: "#b6e3b6", commonName: "Black Gum", category: "Deciduous" },
  "Aralia": { color: "#f5e3b5", commonName: "Aralia", category: "Deciduous" },
  "Prunus": { color: "#f2a6b6", commonName: "Cherry", category: "Deciduous" },
  "Thuja": { color: "#b3e8f2", commonName: "Arborvitae", category: "Coniferous" },
  "Sorbus": { color: "#f5c9d6", commonName: "Rowan", category: "Deciduous" },
  "Ptelea": { color: "#e1f1a6", commonName: "Ptelea", category: "Deciduous" },
  "Eucalyptus": { color: "#c6f4e5", commonName: "Eucalyptus", category: "Deciduous" },
  "Magnolia": { color: "#d4f5b2", commonName: "Magnolia", category: "Deciduous" },
  "Robinia": { color: "#d1e8c5", commonName: "Black Locust", category: "Deciduous" },
  "Cercis": { color: "#f2b1d4", commonName: "Redbud", category: "Deciduous" },
  "Liriodendron": { color: "#e4d1b8", commonName: "Tulip Tree", category: "Deciduous" },
  "Acer": { color: "#c1f0a5", commonName: "Maple", category: "Deciduous" },
  "Cunninghamia": { color: "#b3e0f1", commonName: "Cunninghamia", category: "Coniferous" },
  "Calocedrus": { color: "#e2f5d1", commonName: "Calocedrus", category: "Coniferous" },
  "Pyrus": { color: "#f5d6a3", commonName: "Pear", category: "Deciduous" },
  "Betula": { color: "#f0e5a5", commonName: "Birch", category: "Deciduous" },
  "Lagerstroemia": { color: "#f3d5e8", commonName: "Crape Myrtle", category: "Deciduous" },
  "Zelkova": { color: "#c8e0b6", commonName: "Zelkova", category: "Deciduous" },
  "Gleditsia": { color: "#f2e6a1", commonName: "Honey Locust", category: "Deciduous" },
  "Tsuga": { color: "#b2d1e1", commonName: "Hemlock", category: "Coniferous" },
  "Taxus": { color: "#a2d0b2", commonName: "Yew", category: "Coniferous" },
  "Chamaecyparis": { color: "#b5e8c2", commonName: "False Cypress", category: "Coniferous" },
  "Hippophae": { color: "#e4c9b3", commonName: "Sea Buckthorn", category: "Deciduous" },
  "Ginkgo": { color: "#d1e3a5", commonName: "Ginkgo", category: "Deciduous" },
  "Sophora": { color: "#f2d5c3", commonName: "Sophora", category: "Deciduous" },
  "Sequoia": { color: "#d4e6e1", commonName: "Sequoia", category: "Coniferous" },
  "Laurus": { color: "#b2d6c1", commonName: "Bay Laurel", category: "Evergreen" },
  "Malus": { color: "#f1b2b2", commonName: "Apple", category: "Deciduous" },
  "Rhamnus": { color: "#c8b2e6", commonName: "Buckthorn", category: "Deciduous" },
  "Cupressocyparis": { color: "#b4f5d1", commonName: "Cypress", category: "Coniferous" },
  "Nothofagus": { color: "#f3e5c1", commonName: "Southern Beech", category: "Deciduous" },
  "Euonymus": { color: "#e1f0d4", commonName: "Burning Bush", category: "Deciduous" },
  "Arbutus": { color: "#f2c6b1", commonName: "Strawberry Tree", category: "Evergreen" },
  "Alnus": { color: "#d0e0b2", commonName: "Alder", category: "Deciduous" },
  "Callitropsis": { color: "#b7d2f2", commonName: "Cypress", category: "Coniferous" },
  "Tilia": { color: "#d5f2b2", commonName: "Linden", category: "Deciduous" },
  "Stewartia": { color: "#e6c5f2", commonName: "Stewartia", category: "Deciduous" },
  "Pinus": { color: "#c7f3a5", commonName: "Pine", category: "Coniferous" },
  "Heptacodium": { color: "#f2d6e6", commonName: "Seven Sons Flower", category: "Deciduous" },
  "Hibiscus": { color: "#f1b3d1", commonName: "Hibiscus", category: "Deciduous" },
  "Cedrus": { color: "#c1f0e6", commonName: "Cedar", category: "Coniferous" },
  "Morus": { color: "#f2e4b3", commonName: "Mulberry", category: "Deciduous" },
  "Paulownia": { color: "#e3c9f0", commonName: "Empress Tree", category: "Deciduous" },
  "Amelanchier": { color: "#d1f2d6", commonName: "Serviceberry", category: "Deciduous" },
  "Cornus": { color: "#e0c5b2", commonName: "Dogwood", category: "Deciduous" },
  "Cladrastis": { color: "#e4e5a1", commonName: "Yellowwood", category: "Deciduous" },
  "Juniperus": { color: "#b2d6e1", commonName: "Juniper", category: "Coniferous" },
  "Fagus": { color: "#c6e4b2", commonName: "Beech", category: "Deciduous" },
  "Juglans": { color: "#d9e1c1", commonName: "Walnut", category: "Deciduous" },
  "Albizia": { color: "#f4c3b2", commonName: "Silk Tree", category: "Deciduous" },
  "Michelia": { color: "#f0e1c8", commonName: "Michelia", category: "Evergreen" },
  "Ficus": { color: "#b5f2d7", commonName: "Fig", category: "Deciduous" },
  "Aesculus": { color: "#d1f1b6", commonName: "Buckeye", category: "Deciduous" },
  "Araucaria": { color: "#b2f1e0", commonName: "Monkey Puzzle Tree", category: "Coniferous" },
  "Quercus": { color: "#c1e8b2", commonName: "Oak", category: "Deciduous" },
  "Celtis": { color: "#d2e4c6", commonName: "Hackberry", category: "Deciduous" },
  "Sciadopitys": { color: "#d7e0e4", commonName: "Japanese Umbrella Pine", category: "Coniferous" }
};


export default ColorPalette;
