import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { vancouverBounds } from "./bounds"; // Predefined bounds for the Vancouver area
import LocationMarker from "./locationmarker"; // Custom component for displaying the user's location
import MarkersWithCluster from "./markerswithclusters.js"; // Custom component to manage clustered markers
import { ColorPalette } from "./colorpalette.js";

const Map = ({ onMarkerClick, onOverlayClose, overlayVisible, filterVisible, statsVisible, openFilter, openStats, selectedGenera }) => {
  const [markersData, setMarkersData] = useState([]); // State to hold markers data
  const [isSatelliteView, setIsSatelliteView] = useState(true); // State to toggle between satellite and map view

  // Toggle the tile layer between satellite and map views
  const toggleMapView = () => {
    setIsSatelliteView((prevView) => !prevView);
  };

  // Pass the clicked marker data to the parent component
  const handleMarkerClick = (marker) => {
    if (onMarkerClick) {
      onMarkerClick(marker); // Trigger parent onMarkerClick
    }
  };

  // Fetch markers data from the local street-trees.json file when the component mounts
  useEffect(() => {
    fetch('/street-trees.json')
      .then(response => response.json())
      .then(data => setMarkersData(data)) // Set the markers data
      .catch(error => console.error('Error fetching markers data:', error)); // Log any errors
  }, []);

  // Map reference and default map properties
  const mapRef = useRef(null); // Reference to the map container
  const latitude = 49.78; // Default latitude for centering the map
  const longitude = -123.1; // Default longitude for centering the map
  const zoom = 13; // Default zoom level


  return (
    <div>
      
        {/* Map toggle button */}
          {!statsVisible && !filterVisible && !overlayVisible && (
        <button className="map-toggle-button" onClick={toggleMapView}>
          {isSatelliteView ? "🗺️" : "🌍"} {/* Icons for map and satellite */}
        </button>
        )}

      <MapContainer 
        center={[latitude, longitude]} // Set initial map center
        zoom={zoom} // Set initial zoom level
        ref={mapRef} // Bind the mapRef to the map container
        style={{ height: "100svh", width:"100sh"}} // Full-screen map
        minZoom={13} // Set minimum zoom level
        maxZoom={18} // Set maximum zoom level
        maxBounds={vancouverBounds} // Restrict map panning to Vancouver bounds
        zoomControl={false} // Disable the default zoom control (custom controls likely elsewhere)
      >
        {/* Conditionally render the TileLayer based on isSatelliteView */}
        {isSatelliteView ? (
          <TileLayer
            attribution='<a href="https://www.arcgis.com/home/item.html?id=10df2279f9684e4a9f6a7f08febac2a9">ESRI</a> | <a href="https://opendata.vancouver.ca/explore/dataset/street-trees/information/?disjunctive.species_name&disjunctive.common_name&disjunctive.on_street&disjunctive.neighbourhood_name">Vancouver Open Data Portal</a>'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            preferCanvas={true}
          />
        ) : (
          <TileLayer
            attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | <a href="https://carto.com/attributions">CARTO</a> | <a href="https://opendata.vancouver.ca/explore/dataset/street-trees/information/?disjunctive.species_name&disjunctive.common_name&disjunctive.on_street&disjunctive.neighbourhood_name">Vancouver Open Data Portal</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
            preferCanvas={true}
          />
        )}

        {/* Custom component for displaying user's location */}
        <LocationMarker overlayVisible={overlayVisible} filterVisible={filterVisible} statsVisible={statsVisible}/>

        {/* Tree filter button */}
        {!filterVisible && !overlayVisible && !statsVisible && (
            <button className="filter-toggle-button" onClick={openFilter}>
              <img src="./filtericon.png" alt="Filter Icon" style={{ width: '15px', height: '15px' }} />
            </button>

        )}

        {/* Tree filter button */}
        {!filterVisible && !overlayVisible && !statsVisible && (
            <button className="statistics-toggle-button" onClick={openStats}>
            {"📊"}
            </button>

        )}

        {/* Render markers with clustering enabled */}
        <MarkersWithCluster 
        markersData={markersData} 
        onMarkerClick={handleMarkerClick} 
        availableGenera = {Object.keys(ColorPalette)} 
        selectedGenera={selectedGenera} // Pass selected genera
        />

      </MapContainer>


    </div>
  );
};

export default Map;
