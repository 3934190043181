import React from 'react';

const Header = () => {

  return (
    <div className="header-box">
          <h2>Vancouver Trees</h2>
            <p>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          Zoom & tap any
          <div className="tree-button" style={{ marginRight: '5px', marginLeft: '5px'}}></div>
          for info
        </span>
      </p>
      <a href='https://joostremmers.com' target='_blank' rel='noreferrer' fontColor='rgba(1,111,155,0)' fontSize='11px'>Created by Joost Remmers</a>
    </div>
  );
};

export default Header;
