import React, { useState, useEffect } from 'react';
import GenusInfo from "./genusinfo.js"; // Component for fetching additional genus information

const Overlay = ({ visible, content, onClose }) => {

  // State to manage window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Path to the tree image based on the genus name
  const imageSrc = `/images/${content.genus}.jpeg`; 

  // Close overlay if clicking outside the content box
  const handleOverlayClick = (e) => {
    e.stopPropagation(); // Prevent click from closing when inside the box
  };

  // Determines the article ("a" or "an") based on the common name
  const getArticle = (name) => {
    const firstLetter = name.charAt(0).toLowerCase();
    return 'aeiou'.includes(firstLetter) ? 'an' : 'a';
  };

  // Component that renders the heading with the correct article
  const MyComponent = ({ content }) => (
    <h2>This is {getArticle(content.common_name)} {content.common_name}:</h2>
  );

  // Component that renders the heading with the correct article
  const SmallTitle = ({ content }) => (
    <p className="small-text">Image of a tree from the {content.genus} family but not necessarily {getArticle(content.common_name)} {content.common_name}. 
         Source:<a className="small-link" href='https://wikipedia.com' target='_blank' rel='noreferrer'>Wikipedia</a>
            used under <a className="small-link" href='https://creativecommons.org/licenses/by-sa/2.5/deed.en' target='_blank' rel='noreferrer'>CC BY-SA 4.0</a>
</p>
  );

  // Function to render content based on screen size
  const renderContent = () => {
    if (windowWidth < 700) {
      return (
        <div className="content-wrapper" style={{ flexDirection: 'column' }}>
          {/* Column 1: Tree Information */}
          <div className="info-column" style={{ width: '90%' }}>
            <MyComponent content={content} />
            <p>Genus name: {content.genus}</p>
            {content.species !== 'No' && <p>Species name: {content.species}</p>}
            <p>Height: {content.height}</p>
            <p>Diameter: {content.diameter}'</p>
            
            <h3>Location:</h3>
            <p>Latitude: {content.lat}</p>
            <p>Longitude: {content.lng}</p>
            <p>Street: {content.street}</p>
            <p>Neighbourhood: {content.hood}</p>

            <h3>Statistics:</h3>
            <p>- {content.hood_fact}% of the trees in {content.hood} belong to the genus {content.genus}</p>
            <p>- There are {content.street_fact} more {content.common_name} trees on this street</p>
            <p>- {content.city_fact}% of all trees in Vancouver are of the genus {content.genus}</p>

            {/* Fetch additional genus information using GenusInfo component */}
            <h3>Background:</h3>
            <GenusInfo GenusName={content.genus} />

          <div style={{ width: '100%', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={imageSrc} alt={content.genus} style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px', width: '100%' }} />
          </div>
            <p className="small-text">
            Image of a tree from the {content.genus} family but not necessarily a {content.common_name}. 
            Source: <a className="small-link" href='https://wikipedia.com' target='_blank' rel='noreferrer'>Wikipedia</a>
            used under <a className="small-link" href='https://creativecommons.org/licenses/by-sa/2.5/deed.en' target='_blank' rel='noreferrer'>CC BY-SA 4.0</a>
            </p>
          </div>

          {/* Column 2: Tree Image */}

        </div>
      );
    } else {
      return (
        <div className="content-wrapper" style={{ flexDirection: 'row' }}>
          {/* Column 1: Tree Information */}
          <div className="info-column" style={{ width: '50%' }}>
            <MyComponent content={content} />
            <p>Genus name: {content.genus}</p>
            {content.species !== 'No' && <p>Species name: {content.species}</p>}
            <p>Height: {content.height}</p>
            <p>Diameter: {content.diameter}'</p>
            
            <h3>Location:</h3>
            <p>Latitude: {content.lat}</p>
            <p>Longitude: {content.lng}</p>
            <p>Street: {content.street}</p>
            <p>Neighbourhood: {content.hood}</p>

            <h3>Statistics:</h3>
            <p>- {content.hood_fact}% of the trees in {content.hood} belong to the genus {content.genus}</p>
            <p>- There are {content.street_fact} more {content.common_name} trees on this street</p>
            <p>- {content.city_fact}% of all trees in Vancouver are of the genus {content.genus}</p>

            {/* Fetch additional genus information using GenusInfo component */}
            <h3>Background:</h3>
            <GenusInfo GenusName={content.genus} />
          </div>

          {/* Column 2: Tree Image */}
          <div className="image-column" style={{ width: '50%', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>            <img src={imageSrc} alt={content.genus} style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px', width: '100%' }} />
            <SmallTitle content={content} />. 
          </div>

        </div>
      );
    }
  };

  return (
     <div 
      className="overlay-background" 
      style={{ display: visible ? 'block' : 'none' }}
      onClick={onClose}
    >
      <div className="overlay-box" onClick={handleOverlayClick}>
        {/* Close Button */}
        <button 
          onClick={onClose} 
          style={{ 
            position: 'absolute', 
            top: '10px', 
            right: '10px', 
            color: 'black',
            backgroundColor: 'transparent', 
            border: 'none', 
            fontSize: '20px', 
            cursor: 'pointer'
          }}
        >
          &times;
        </button>
        {renderContent()}
      </div>
    </div>
  );
};

export default Overlay;
