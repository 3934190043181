import React, { useState, useEffect, useCallback } from "react";
import { Marker, useMapEvents } from "react-leaflet";
import { blueDotIcon } from "./icons.js"; // Custom blue dot icon for location marker
import { vancouverBounds } from "./bounds.js"; // Boundary for location validity check

export default function LocationMarker({ overlayVisible , filterVisible, statsVisible}) {
  const [position, setPosition] = useState(null); // State to store user's location
  const map = useMapEvents({}); // Access Leaflet map instance

  // Function to trigger geolocation
  const findLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latlng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          handleLocationFound({ latlng }); // Manually call the handler
        },
        (error) => {
          console.error("Error getting location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported.");
    }
  };

  // Memoized event handler when location is found
  const handleLocationFound = useCallback((e) => {
    setPosition(e.latlng); // Set user's location
    map.flyTo(e.latlng, 18); // Zoom to user's location
  }, [map]);

  // Add locationfound and moveend event listeners
  useEffect(() => {
    map.on('locationfound', handleLocationFound); // Event when location is found
    map.on('moveend', () => {
      });

    // Cleanup event listeners on component unmount
    return () => {
      map.off('locationfound', handleLocationFound);
      map.off('moveend');
    };
  }, [map, handleLocationFound]); // Added handleLocationFound to dependencies

  // Check if the user's location is within Vancouver bounds
  const isWithinBounds =
    position &&
    position.lat >= vancouverBounds[0][0] &&
    position.lat <= vancouverBounds[1][0] &&
    position.lng >= vancouverBounds[0][1] &&
    position.lng <= vancouverBounds[1][1];

  return (
    <>
      {/* Only show the marker if the position is set and within bounds */}
      {position && isWithinBounds && (
        <Marker position={position} icon={blueDotIcon} />
      )}

      {/* Show location button only if it's visible and overlay is not visible */}
      {!statsVisible && !filterVisible && !overlayVisible && (
        <button onClick={findLocation} onTouchStart={findLocation} className="location-button">
          <div className="static-button"></div>
        </button>
      )}
    </>
  );
}
